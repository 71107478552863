var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor",attrs:{"id":"editor"}},[_c('div',{staticClass:"form-editor"},[(_vm.componentData.length <= 0)?_c('div',{staticClass:"info"},[_vm._v("表单区域")]):_vm._e(),_c('div',{staticClass:"form-container",on:{"drop":_vm.handleFormDrop,"dragover":_vm.handleFormDragOver}},[(_vm.refresh)?_c('el-form-schema',{ref:"editEfs",attrs:{"schema":_vm.efsSchema,"editMode":"edit","is-search-form":true,"inline":_vm.inline,"use-enter-search":false,"expand-number":_vm.expandNumber,"size":_vm.size},on:{"submit":_vm.submit},model:{value:(_vm.copyFormValue),callback:function ($$v) {_vm.copyFormValue=$$v},expression:"copyFormValue"}}):_vm._e()],1),_c('div',{staticClass:"editBtn-editor"},[(_vm.buttonData.length <= 0)?_c('div',{staticClass:"info"},[_vm._v("操作区域")]):_vm._e(),_c('div',{staticClass:"editBtn-container",on:{"drop":_vm.handleBtnDrop,"dragover":_vm.handleBtnDragOver}},[_c('EditButton',{attrs:{"schema":_vm.buttonData,"editMode":"edit"}})],1)]),_c('div',{staticClass:"table-editor"},[(_vm.tableData.length <= 0)?_c('div',{staticClass:"info"},[_vm._v("表格区域")]):_vm._e(),_c('div',{staticClass:"table-container",on:{"drop":_vm.handleTableDrop,"dragover":_vm.handleTableDragOver}},[_c('EasyTable',{attrs:{"columns":_vm.tableColumns,"tableData":_vm.tableJson,"tableOption":{
            border: true,
          },"pageOption":{
            size: _vm.pageSize,
            curPage: _vm.pageCurpage,
            sizes: _vm.pageSizes,
            total: _vm.pageTotal,
          },"editMode":"edit","sizeChange":_vm.onSizeChange}})],1)]),_c('div',{staticClass:"dialog-editor"},[(_vm.dialogData.length <= 0)?_c('div',{staticClass:"info"},[_vm._v("弹框区域")]):_vm._e(),_c('div',{staticClass:"dialog-container",on:{"drop":_vm.handleDialogDrop,"dragover":_vm.handleDialogDragOver}},[_c('EasyDialog',{attrs:{"schema":_vm.dialogData,"dialogVisible":_vm.dialogValue,"editMode":"edit"}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }