var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"bg"},[_c('el-button',{staticClass:"close",on:{"click":_vm.close}},[_vm._v("关闭")]),_c('div',{staticClass:"canvas-container"},[_c('div',{staticClass:"canvas",staticStyle:{"width":"1200px","height":"700px","padding":"30px","margin-top":"100px"}},[_c('el-form-schema',{ref:"previewEfs",attrs:{"schema":JSON.parse(JSON.stringify(_vm.efsSchema)),"editMode":"preview","is-search-form":true,"inline":_vm.inline,"use-enter-search":false,"expand-number":_vm.expandNumber,"size":_vm.size},on:{"submit":_vm.submit},model:{value:(_vm.copyFormValue),callback:function ($$v) {_vm.copyFormValue=$$v},expression:"copyFormValue"}}),_c('div',{staticClass:"edit-container"},[_c('EditButton',{attrs:{"schema":_vm.buttonData,"editMode":"preview"}})],1),_c('div',{staticClass:"table-container"},[_c('EasyTable',{attrs:{"columns":_vm.tableSchema,"tableData":_vm.tableJson,"tableOption":{
            border: true,
          },"pageOption":{
            size: _vm.pageSize,
            curPage: _vm.pageCurpage,
            sizes: _vm.pageSizes,
            total: _vm.pageTotal,
          },"editMode":"preview"}})],1),_c('div',{staticClass:"dialog-container"},[_c('EasyDialog',{attrs:{"schema":_vm.dialogData,"dialogVisible":_vm.dialogValue,"editMode":"preview"}})],1)],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }